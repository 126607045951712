import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'Hooks/use-fetch';
import { useService, useStores, useWindowDimensions } from 'Hooks';
import { ConcessionService } from 'Services/ConcessionService';
import { useParams } from 'react-router-dom';
import Skeleton from 'Components/Skeleton';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Pin from './Pin.png';
import { GooglemapMapIcon, GooglemapPhoneIcon, GooglemapWorldIcon } from 'Components/icons';
import { theme } from 'Style/theme';
import { displayAddress } from 'Utils/TextUtils';
import DefaultImg from 'Assets/Images/concession_default_img.webp';
import PostulerButton from 'Components/postuler-button';
import { observer } from 'mobx-react';
import { ValuesDefinitions } from 'Models/ValuesDefinitions';
import { RecruitmentConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/RecruitmentConcessionPublicDetailsDto';

const ConcessionDetails = observer(() => {
    const MOBILE_BREAKPOINT = 700;
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const { concessionSlug } = useParams<{ concessionSlug: string }>();
    const { apiRequest, loadingStateKeys } = useFetch();
    const concessionService = useService(ConcessionService);
    const { userStore } = useStores();

    const [concession, setConcession] = useState<RecruitmentConcessionPublicDetailsDto>();

    const [applicationSent, setApplicationSent] = useState(false);

    const { isLoaded: googleMapScriptIsLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: window.Environment.REACT_APP_GOOGLE_API_KEY ?? '',
        // mapIds: [] see if required
    });

    const fetchConcession = useCallback(async () => {
        const response: RecruitmentConcessionPublicDetailsDto | null = await apiRequest({
            requestFunction: () => concessionService.getRecruitmentConcessionPublic(concessionSlug),
            requestParameters: undefined,
            loadingStateKey: 'concession',
        });

        if (response) {
            setConcession(response);
        }
    }, [apiRequest, concessionService, concessionSlug]);

    useEffect(() => {
        fetchConcession();
    }, [fetchConcession]);

    return (
        <div className="ConcessionDetails">
            <div className="inner-content">
                <Skeleton
                    placeholder={
                        <div style={{ width: '100%' }}>
                            <div className="rect" style={{ height: 468 }} />
                        </div>
                    }
                    isLoading={loadingStateKeys.has('concession')}
                >
                    <>
                        <div className="image-section">
                            <div className="left">
                                {concession?.logo?.url && (
                                    <div className="logo">
                                        <img src={concession.logo.url} />
                                    </div>
                                )}

                                <div className="name custom-font text-white">
                                    {concession?.name}
                                </div>
                                <div className="marque uppercase text-white text-title-medium-bold">
                                    {t('marques')}:&nbsp;
                                    {concession?.makes?.map((x) => x?.name).join(', ')}
                                </div>
                                <div className="slogan custom-font text-white">
                                    {concession?.slogan}
                                </div>

                                <PostulerButton
                                    type="red"
                                    className="postuler"
                                    concessionId={concession?.id}
                                    userId={userStore.userInfo?.id}
                                    concessionIsHiring={concession?.isHiring ?? false}
                                    width="hugged"
                                    applicationSent={applicationSent}
                                    onApplicationSent={() => setApplicationSent(true)}
                                />
                            </div>

                            <div className="right">
                                <img src={concession?.mainImage?.url ?? DefaultImg} />
                            </div>
                        </div>

                        {concession?.description && (
                            <div className="description-section">
                                <div className="custom-font text-primary title">
                                    {t('a_propos')}
                                </div>
                                <div
                                    className="desc text-body-large text-primary"
                                    dangerouslySetInnerHTML={{ __html: concession.description }}
                                />
                            </div>
                        )}

                        {concession?.values && (
                            <div className="valeur-section">
                                <div className="title custom-font text-white uppercase">
                                    {t('nos_valeurs')}
                                </div>

                                <div className="values-container">
                                    {concession.values.map((value) => (
                                        <div className="value-box" key={value?.id ?? ''}>
                                            <div className="custom-font value">
                                                {
                                                    ValuesDefinitions.find(
                                                        (x) => x.id === value?.id
                                                    )?.title
                                                }
                                            </div>
                                            <div className="text-body-large">
                                                {
                                                    ValuesDefinitions.find(
                                                        (x) => x.id === value?.id
                                                    )?.description
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="postuler-section">
                            <div className="custom-font text text-white uppercase">
                                {t('Quiz.pret_a_commencer')}
                            </div>
                            <div className="buttonContainer">
                                <PostulerButton
                                    type="white-red-text"
                                    concessionId={concession?.id}
                                    userId={userStore.userInfo?.id}
                                    concessionIsHiring={concession?.isHiring ?? false}
                                    width="full"
                                    className="postuler-button"
                                    applicationSent={applicationSent}
                                    onApplicationSent={() => setApplicationSent(true)}
                                />
                            </div>
                        </div>

                        <div className="contact-section">
                            <div className="left">
                                <div className="text-white custom-font">{t('contactez_nous')}</div>
                                <div className="divider" />
                                <div className="relative-info">
                                    {concession?.address && (
                                        <div className="text-body-large text-primary">
                                            <GooglemapMapIcon fill={theme.white} />
                                            {displayAddress(concession?.address)}
                                        </div>
                                    )}

                                    {concession?.recruitmentContactInfo?.website && (
                                        <div className="text-body-large text-primary">
                                            <GooglemapWorldIcon fill={theme.white} />
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={
                                                    concession?.recruitmentContactInfo?.website ??
                                                    ''
                                                }
                                                className="text-white"
                                            >
                                                {concession?.recruitmentContactInfo?.website}
                                            </a>
                                        </div>
                                    )}

                                    {concession?.recruitmentContactInfo?.phoneNumber && (
                                        <div className="text-body-large text-primary">
                                            <GooglemapPhoneIcon fill={theme.white} />
                                            {concession?.recruitmentContactInfo?.phoneNumber}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="right">
                                {concession?.latitude && googleMapScriptIsLoaded && (
                                    <GoogleMap
                                        mapContainerClassName="map-container"
                                        center={{
                                            lat: concession?.latitude ?? 0,
                                            lng: concession?.longitude ?? 0,
                                        }}
                                        zoom={13}
                                        options={{
                                            gestureHandling:
                                                windowWidth > MOBILE_BREAKPOINT
                                                    ? 'greedy'
                                                    : 'cooperative',
                                            disableDefaultUI: true,
                                            minZoom: 8,
                                            //remove all points of interest google adds to map so not to be so busy
                                            styles: [
                                                {
                                                    elementType: 'labels',
                                                    featureType: 'poi',
                                                    stylers: [{ visibility: 'off' }],
                                                },
                                            ],
                                        }}
                                    >
                                        <Marker
                                            position={{
                                                lat: concession?.latitude ?? 0,
                                                lng: concession?.longitude ?? 0,
                                            }}
                                            icon={Pin}
                                        />
                                    </GoogleMap>
                                )}
                            </div>
                        </div>
                    </>
                </Skeleton>
            </div>
        </div>
    );
});

export default ConcessionDetails;
